var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(this.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "service-form",
            submitAction: _vm.create,
            submitSuccessAction: _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.service.name,
                  callback: function($$v) {
                    _vm.$set(_vm.service, "name", $$v)
                  },
                  expression: "service.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "description",
                  placeholder: _vm.$t("shared.description")
                },
                model: {
                  value: _vm.service.description,
                  callback: function($$v) {
                    _vm.$set(_vm.service, "description", $$v)
                  },
                  expression: "service.description"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasMultipleOffices,
                  expression: "!hasMultipleOffices"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("app-numerictextbox", {
                attrs: {
                  id: "price",
                  placeholder: _vm.$t("shared.price"),
                  format: "C2"
                },
                on: { input: _vm.onPriceValueChanged },
                model: {
                  value: _vm.price,
                  callback: function($$v) {
                    _vm.price = $$v
                  },
                  expression: "price"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist-yes-no", {
                attrs: {
                  id: "multipleParticipants",
                  placeholder: _vm.$t("services.multipleParticipants")
                },
                model: {
                  value: _vm.service.multipleParticipants,
                  callback: function($$v) {
                    _vm.$set(_vm.service, "multipleParticipants", $$v)
                  },
                  expression: "service.multipleParticipants"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist-yes-no", {
                attrs: {
                  id: "roomRequired",
                  placeholder: _vm.$t("services.roomRequired")
                },
                model: {
                  value: _vm.service.roomRequired,
                  callback: function($$v) {
                    _vm.$set(_vm.service, "roomRequired", $$v)
                  },
                  expression: "service.roomRequired"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist-yes-no", {
                attrs: {
                  id: "roomMandatory",
                  placeholder: _vm.$t("services.roomMandatory"),
                  enabled: _vm.roomMandatoryEnabled
                },
                model: {
                  value: _vm.service.roomMandatory,
                  callback: function($$v) {
                    _vm.$set(_vm.service, "roomMandatory", $$v)
                  },
                  expression: "service.roomMandatory"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist-yes-no", {
                attrs: {
                  id: "equipmentRequired",
                  placeholder: _vm.$t("services.equipmentRequired")
                },
                model: {
                  value: _vm.service.equipmentRequired,
                  callback: function($$v) {
                    _vm.$set(_vm.service, "equipmentRequired", $$v)
                  },
                  expression: "service.equipmentRequired"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist-yes-no", {
                attrs: {
                  id: "equipmentMandatory",
                  placeholder: _vm.$t("services.equipmentMandatory"),
                  enabled: _vm.equipmentMandatoryEnabled
                },
                model: {
                  value: _vm.service.equipmentMandatory,
                  callback: function($$v) {
                    _vm.$set(_vm.service, "equipmentMandatory", $$v)
                  },
                  expression: "service.equipmentMandatory"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasMultipleOffices,
                  expression: "hasMultipleOffices"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("h4", { staticStyle: { margin: "12px 0 6px" } }, [
                _vm._v(" " + _vm._s(_vm.$t("offices.offices")) + " ")
              ]),
              _c(
                "ejs-grid",
                {
                  attrs: {
                    allowSorting: true,
                    dataSource: _vm.service.offices,
                    editSettings: _vm.officesGrid.editSettings,
                    sortSettings: _vm.officesGrid.sortOptions,
                    toolbar: _vm.officesGrid.toolbar,
                    actionBegin: _vm.officesGridActionBegin,
                    actionComplete: _vm.officesGridActionComplete
                  }
                },
                [
                  _c(
                    "e-columns",
                    [
                      _c("e-column", {
                        attrs: {
                          field: "officeId",
                          headerText: _vm.$t("shared.id"),
                          isPrimaryKey: true,
                          width: "0",
                          validationRules:
                            _vm.officesGrid.validationOptions.rules.officeId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "officeName",
                          headerText: _vm.$t("offices.office"),
                          width: "200"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "price",
                          headerText: _vm.$t("shared.price"),
                          width: "90",
                          textAlign: "right",
                          format: "C2",
                          validationRules:
                            _vm.officesGrid.validationOptions.rules.price
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          width: "90",
                          commands: _vm.officesGrid.commands
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.create",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }